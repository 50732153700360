import React from "react";
import Image from "next/legacy/image";
import { Grid } from "@/components/shared/Grid";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

type ThreeImagesBlockProps = GetDynamicContentItemProps<"ThreeImagesBlock">;

const ThreeImagesBlock: React.FC<ThreeImagesBlockProps> = ({ images, description, className }) => {
  if (!images) return null;
  return (
    <Grid dataBlock="ThreeImagesBlock" className={`relative ${className ?? ""}`}>
      <div className="tablet:gap-8 col-span-full flex w-full flex-row gap-2">
        {images.map((image, i) => {
          if (!image?.default) return null;
          return (
            <div key={i} className="relative flex aspect-square flex-1">
              <Image
                src={image.default}
                alt={image.description ?? ""}
                layout="fill"
                objectFit="cover"
              />
            </div>
          );
        })}
      </div>
      {description && (
        <p className="text-p-xs text-secondary-burgundy tablet:mt-6 tablet:text-p col-span-full mt-4 font-serif tracking-wider">
          {description}
        </p>
      )}
    </Grid>
  );
};

export default ThreeImagesBlock;
