import Image, { ImageProps } from "next/legacy/image";
import React from "react";
import styles from "./ResponsiveImage.module.css"; // This is a hack to circumvent Image from requiring a set height

type ResponsiveImageProps = ImageProps;

const ResponsiveImage: React.FC<ResponsiveImageProps> = (props) => (
  <div className={`${styles.imageContainer} ${props.className ?? ""}`}>
    <Image {...props} layout="fill" className={styles.image} alt={props.alt ?? ""} />
  </div>
);

export default ResponsiveImage;
