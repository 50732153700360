import React from "react";
import ArrowRightTwo from "@/components/Icons/ArrowRightTwo";
import TextLink from "@/components/shared/TextLink";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { SubHeaderLinksBlockProps } from "../SubHeaderLinksBlock";

type SubHeaderLinkProps = Path<SubHeaderLinksBlockProps, ["links", number]>;

const SubHeaderLink: React.FC<SubHeaderLinkProps> = ({ target, text }) => {
  const { type, href, onClick, isValid } = useButtonTarget(target[0]);
  if (!isValid || !text) return null;

  return (
    <li>
      <TextLink
        type={type}
        href={href}
        onClick={onClick}
        variant="white"
        className="tablet:justify-start w-full justify-between !pb-2"
      >
        {text}
        <ArrowRightTwo
          className={`tablet:ml-4 size-8 ${
            target[0].__typename === "AnchorLink" ? "rotate-90" : "rotate-0"
          }`}
        />
      </TextLink>
    </li>
  );
};

export default SubHeaderLink;
