import Image from "next/legacy/image";
import Highlights from "@/components/shared/Highlights";
import { Grid } from "@/components/shared/Grid";
import { parseText } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

type BigUspBlockProps = GetDynamicContentItemProps<"BigUspBlock">;

const BigUspBlock: React.FC<BigUspBlockProps> = ({
  heading1,
  heading2,
  body,
  image,
  highlights,
}) => (
  <section data-block="BigUspBlock" className="tablet:mt-32 mt-14">
    <Grid className="overflow-hidden">
      <p className="text-p text-secondary-aubergine tablet:col-start-2 tablet:mb-6 tablet:text-intro col-span-full row-start-1 mb-4 font-serif tracking-wider">
        {heading1}
      </p>
      <h2 className="text-h2-sm tablet:col-span-4 tablet:col-start-2 tablet:mb-20 tablet:text-h2 col-span-full row-start-2 mb-6 font-bold tracking-wider">
        {heading2}
      </h2>
      {body && (
        <div className="text-p-sm tablet:col-span-5 tablet:col-start-7 tablet:row-start-2 tablet:mb-20 tablet:text-p col-span-full row-start-3 mb-6 font-serif tracking-wider">
          {parseText(body)}
        </div>
      )}
      {image?.[0]?.default && (
        <>
          <div className="tablet:col-span-10 tablet:col-start-2 tablet:row-start-3 tablet:mb-20 tablet:h-[31.75rem] relative col-span-full row-start-4 mb-10 h-[13.75rem]">
            <Image
              src={image?.[0]?.default}
              alt={image?.[0]?.description || "promo image"}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="tablet:row-start-3 tablet:mt-16 relative col-span-full row-span-2 row-start-4 mt-10">
            <div className="bg-primary-black absolute -inset-x-10 -top-0 bottom-0 -z-10 opacity-[0.02]" />
          </div>
        </>
      )}

      {highlights && (
        <Highlights
          highlights={highlights}
          className="tablet:row-start-4 tablet:pb-20 row-start-5 pb-10"
        />
      )}
    </Grid>
  </section>
);

export default BigUspBlock;
