import React, { ChangeEvent, RefObject } from "react";
import { RefCallBack } from "react-hook-form";
import clsx from "clsx";
import ChevronDownTwo from "../Icons/ChevronDownTwo";

type SelectListProps = {
  name?: string;
  inputRef?: RefObject<HTMLSelectElement> | RefCallBack;
  label?: string;
  placeholder?: string;
  selectedOption?: string | number;
  options: Record<string, string>;
  onChange: (item: string) => void;
  onBlur?: (item: string) => void;
  error?: React.ReactNode;
  className?: string;
  order?: "unordered" | "ascending" | "descending" | "key";
  selectClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  dataGtmId?: string;
};

const SelectList: React.FC<SelectListProps> = ({
  label,
  placeholder,
  selectedOption,
  options,
  onChange,
  onBlur,
  error,
  className,
  order = "unordered",
  selectClassName,
  labelClassName,
  disabled = false,
  dataGtmId,
  name,
  inputRef,
}) => {
  const errorClass = error
    ? "border-secondary-aubergine text-secondary-aubergine"
    : "border-grey-asphalt text-primary-black";
  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };
  const onBlurHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onBlur) onBlur(event.target.value);
  };
  const sortedOptions =
    order === "unordered"
      ? Object.entries(options)
      : Object.entries(options).sort(([key1, value1], [key2, value2]) =>
          order == "key"
            ? key1.localeCompare(key2, "en", { numeric: true })
            : order == "descending"
            ? value2.localeCompare(value1)
            : value1.localeCompare(value2)
        );
  return (
    <div className={`${className ?? ""}`}>
      {label && (
        <label
          htmlFor={label}
          className={clsx(
            "text-label-xs text-primary-black mb-3 block font-sans font-bold tracking-wider",
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <div className="relative flex">
        <select
          name={name}
          ref={inputRef}
          value={selectedOption}
          className={clsx(
            "text-label-xs invalid:text-primary-black/50 focus:outline-secondary-aubergine w-full select-none appearance-none truncate rounded-lg border px-6 py-3 pr-10 placeholder:font-normal invalid:font-normal hover:-m-px hover:border-2 focus:-m-px focus:border-2",
            errorClass,
            disabled ? "font-normal" : "font-bold",
            selectClassName
          )}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          disabled={disabled}
          required
          data-gtm-id={dataGtmId}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {sortedOptions.map(([key, value], i) => {
            return (
              <option value={key} key={`option-${i}`}>
                {value}
              </option>
            );
          })}
        </select>
        <ChevronDownTwo className="text-primary-black pointer-events-none absolute right-4 top-1/2 size-6 -translate-y-1/2 select-none" />
      </div>
      {error && (
        <div className="border-secondary-grapefruit text-p-sm text-secondary-aubergine -z-10 -mt-2 w-full rounded-lg border bg-white px-6 pb-4 pt-5 font-bold">
          {error}
        </div>
      )}
    </div>
  );
};

export default SelectList;
