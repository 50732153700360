import Image from "next/legacy/image";
import { Path } from "@/utilities/types";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { PreprLeaseCalculatorPageProps } from "@/services/prepr/queries/getLeaseCalculatorPage";
import Button from "../shared/Button";
import { LeaseCalculatorCtaProps } from "./LeaseCalculatorCta";

type LeaseCalculatorNotAvailableProps = {
  title: Path<LeaseCalculatorCtaProps, ["not_available_title"]>;
  subtitle: Path<LeaseCalculatorCtaProps, ["not_available_subtitle"]>;
  description: Path<LeaseCalculatorCtaProps, ["not_available_description"]>;
  image: Path<LeaseCalculatorCtaProps, ["image"]>;
  button_1_tel: Path<PreprLeaseCalculatorPageProps, ["button_phone_number"]>;
  button_2_text: Path<PreprLeaseCalculatorPageProps, ["button2_text"]>;
  button_2_target?: Path<PreprLeaseCalculatorPageProps, ["button2_target"]>;
};

const LeaseCalculatorNotAvailableBlock = ({
  title,
  subtitle,
  description,
  button_1_tel,
  button_2_text,
  button_2_target,
  image,
}: LeaseCalculatorNotAvailableProps) => {
  const button_2 = useButtonTarget(button_2_target?.[0]);
  return (
    <>
      <div className="tablet:col-start-2 tablet:mt-10 col-span-7 mt-3">
        <p className="text-p text-primary-mint tablet:mb-4 tablet:text-h3-sm mb-3 font-serif tracking-wider">
          {title}
        </p>
        <h2 className="text-h2-sm tablet:text-4xl mb-8 font-sans font-bold tracking-wider">
          {subtitle}
        </h2>
        <p className="leading-8">{description}</p>
      </div>
      <div className="tablet:col-span-6 tablet:col-start-2 tablet:mb-20 tablet:flex desktop:col-span-4 desktop:col-start-2 col-span-6 mt-10 grid justify-between">
        <Button
          className="desktop-s:text-l col-span-6 text-base"
          variant="tertiary"
          type="link"
          href={`tel:${button_1_tel}`}
          icon={
            <Image src="/icons/contact-telephone.svg" width="24" height="24" alt="telephone icon" />
          }
          iconPosition="start"
        >
          {button_1_tel ?? ""}
        </Button>
        <Button
          className="tablet:mt-0 col-span-6 mt-6"
          variant="tertiary"
          type={button_2.type}
          href={button_2.href}
          onClick={button_2.onClick}
        >
          {button_2_text ?? ""}
        </Button>
      </div>
      <div className="tablet:block desktop-xs:right-20 desktop-xl:right-56 absolute right-10 top-full hidden h-[450px] w-[320px] -translate-y-full">
        <div className="relative size-full ">
          {image?.[0]?.default && (
            <Image
              src={image?.[0]?.default}
              alt={image?.[0]?.description || ""}
              layout="fill"
              objectFit="cover"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LeaseCalculatorNotAvailableBlock;
